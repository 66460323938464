//pages/Home.js

import React, { useEffect, useState } from "react";
import { MdInfoOutline, MdOutlineCancel } from "react-icons/md";


export default function Home() {
    const [popularApt, setPopularApt] = useState([]);
    const [popularArea, setPopularArea] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("apt");
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        if (activeTab === "apt") {
            getPopularApt();
        } else {
            getPopularArea();
        }
    }, [activeTab]);


    const getPopularApt = () => {
        setLoading(true);
        const apiUrl = `https://apartment.codok.org/popular/apt`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/popular/apt`;
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setPopularApt(data);
        })
        .catch((error) => {
            console.error('There was a problem with the fetchComplexes:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const getPopularArea = () => {
        setLoading(true);
        const apiUrl = `https://apartment.codok.org/popular/area`; // 실제 API 엔드포인트를 넣어주세요 
        // const apiUrl = `http://127.0.0.1:5001/popular/area`;
        fetch(apiUrl)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setPopularArea(data);
        })
        .catch((error) => {
            console.error('There was a problem with the fetchComplexes:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    return (
        <section className="flex flex-col box-border">
            <div className="p-5 lg:my-5 lg:mx-64 border rounded-lg shadow-md bg-yellow-50 w-68 flex flex-col justify-between">
                <div className="flex justify-center items-center relative mb-3">
                    <h1 className="font-bold text-lg text-center">지금 인기</h1>
                    <button 
                        className="ml-2 text-gray-500 focus:outline-none"
                        onClick={() => setShowTooltip(!showTooltip)}
                    >
                        <MdInfoOutline />
                    </button>
                    {showTooltip && (
                        <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 p-3 w-64 bg-white border border-gray-300 rounded-lg shadow-md">
                        <div className="flex justify-between items-center mb-2">
                            <h2 className="font-bold text-sm">지금 인기 아파트·지역</h2>
                            <button onClick={() => setShowTooltip(false)} className="focus:outline-none">
                                <MdOutlineCancel />
                            </button>
                        </div>
                        <p className="text-sm text-gray-700">아파트, 지역 페이지 방문자수를 기준으로 아파트 순위를 제공합니다.</p>
                        </div>
                    )}
                </div>
                <div className="flex justify-evenly space-x-2 border-b mb-4 pb-2">
                    <button
                        onClick={() => setActiveTab("apt")}
                        className={`text-sm font-semibold px-4 py-2 border rounded-md ${activeTab === "apt" ? "bg-orange-300 text-white" : "bg-gray-200 text-gray-700"}`}
                    >
                        아파트
                    </button>
                    <button
                        onClick={() => setActiveTab("area")}
                        className={`text-sm font-semibold px-4 py-2 border rounded-md ${activeTab === "area" ? "bg-orange-300 text-white" : "bg-gray-200 text-gray-700"}`}
                    >
                        지역
                    </button>
                </div>
                {loading ? (
                    <p className="text-center font-semibold text-lg">Loading...</p>
                    ) : activeTab === "apt" ? (
                        <ul>
                        {popularApt.map((apartment, index) => (
                            <a key={apartment.complexNo} href={`/apartment/${apartment.complexNo}`}>
                                <li className="flex justify-between items-center py-3 border-b">
                                    <span className="font-medium text-base">{index + 1}</span>
                                    <div className="flex-1 ml-4">
                                        <p className="font-semibold text-black text-lg">{apartment.complexName}</p>
                                        <p className="text-sm text-gray-500 mt-1">{apartment.legalDivisionAddress}</p>
                                    </div>
                                    <p className="font-bold text-green-600">{apartment.viewCount.toLocaleString()}명</p>
                                </li>
                            </a>
                            ))}
                        </ul>
                    ) : (
                    <ul>
                        {popularArea.map((area, index) => (
                        <a key={area.id} href={`/area/${area.region}/${area.cityCountyDistrict}/${area.townVillageNeighborhood}`}>
                            <li key={area.id} className="flex justify-between items-center py-3 border-b">
                                <span className="font-medium text-base">{index + 1}</span>
                                <div className="flex-1 ml-4">
                                <p className="font-semibold">{area.legalDivisionAddress}</p>
                                </div>
                                <p className="font-bold text-green-600">{area.viewCount.toLocaleString()}명</p>
                            </li>
                        </a>
                        ))}
                    </ul>
                    )}
            </div>
        </section>
    );
}

