// src/components/Navbar.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='flex flex-col lg:flex-row justify-between lg:justify-end items-center lg:w-1/3'>
      <Link to="/" className='text-lg lg:text-2xl p-2'>홈</Link>
      <Link to="/apt" className='text-lg lg:text-2xl p-2'>아파트</Link>
      {/* <a href="/about" className='text-lg lg:text-2xl p-2'>About</a> */}
      <Link to="/area" className='text-lg lg:text-2xl p-2'>지역별</Link>
      {/* <a href="/pilot" className='text-lg lg:text-2xl p-2'>Pilot</a> */}
      <Link to="/contact" className='text-lg lg:text-2xl p-2'>문의사항</Link>
    </nav>
  );
};

export default Navbar;
